/* eslint-disable no-console */
import React, { useState } from "react"
import { useEffect } from "react"
import Input from "../../components/Input"
const BillingDetails = ({
  form_bl,
  form_deliver,
  handleDeliverChange,
  handleBillingChange,
  viewOtherAddress,
  setViewOtherAddress,
  error_form_bl,
  error_form_deliver,
}) => {
  const classView = viewOtherAddress ? "active" : ""
  const [showError, setShowError] = useState()
  const handleValidate = e => {
    if (!e.target.value) {
      setShowError(e.target.name)
    }
  }
  useEffect(() => {
    if(showError) {
      const timer = setTimeout(() => {
        setShowError("")
      },2000)
      return () => clearTimeout(timer)
    }
  },[showError])
  return (
    <div className="billing-details">
      <div className="row-wrap row-left">
        <h2 className="title-details">Billing details</h2>
        <div className="row row-name">
          <div className="col">
            <Input
              label="First name"
              required
              error=""
              value={form_bl ? form_bl.first_name : ""}
              onChange={handleBillingChange}
              type="text"
              name="first_name"
              onBlur={handleValidate}
            />
            {error_form_bl?.first_name ? (
              <p className="error">{error_form_bl?.first_name}</p>
            ) : null}
            {showError === "first_name" ? <p className="error">This field is required</p> : null}
          </div>
          <div className="col">
            <Input
              value={form_bl ? form_bl.last_name : ""}
              onChange={handleBillingChange}
              label="Last name"
              required
              error=""
              type="text"
              name="last_name"
              onBlur={handleValidate}
            />
            {error_form_bl?.last_name ? <p className="error">{error_form_bl?.last_name}</p> : null}
            {showError === "last_name" ? <p className="error">This field is required</p> : null}
          </div>
        </div>
        <div className="row row-field not-require company-field">
          <Input
            value={form_bl?.company}
            onChange={handleBillingChange}
            label="Company name (optional)"
            error=""
            type="text"
            name="company"
          />
        </div>
        <div className="row row-field row-select">
          <label required htmlFor="">
            Country/Region
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          <strong>Australia</strong>
        </div>
        <div className="row row-field street-first">
          <Input
            value={form_bl?.house_number}
            onChange={handleBillingChange}
            className="input-street"
            label="Street address"
            placeholder="House number and street name"
            required
            type="text"
            error=""
            name="house_number"
            onBlur={handleValidate}
          />
          {error_form_bl?.house_number ? (
            <p className="error">{error_form_bl?.house_number}</p>
          ) : null}
          {showError === "house_number" ? <p className="error">This field is required</p> : null}
        </div>
        <div className="row row-field not-require street-none">
          <Input
            value={form_bl?.apartment}
            onChange={handleBillingChange}
            className="input-street"
            placeholder="Apartment, suite, unit, etc. (optional)"
            error=""
            type="text"
            name="apartment"
          />
        </div>
        <div className="row row-field sub-field">
          <Input
            value={form_bl?.suburb}
            onChange={handleBillingChange}
            label="Suburb"
            required
            error=""
            type="text"
            name="suburb"
            onBlur={handleValidate}
          />
          {error_form_bl?.suburb ? <p className="error">{error_form_bl?.suburb}</p> : null}
          {showError === "suburb" ? <p className="error">This field is required</p> : null}
        </div>
        <div className="row row-field row-select">
          <label htmlFor="regions">
            State
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          <select
            name="state"
            value={form_bl?.state}
            onChange={handleBillingChange}
            id="regions"
            required
            onBlur={handleValidate}
          >
            <option value="">Select an option…</option>
            <option value="ACT">Australian Capital Territory</option>
            <option value="NSW">New South Wales</option>
            <option value="NT">Northern Territory</option>
            <option value="QLD">Queensland</option>
            <option value="SA">South Australia</option>
            <option value="TAS">Tasmania</option>
            <option value="VIC">Victoria</option>
            <option value="WA">Western Australia</option>
          </select>
          {error_form_bl?.state ? <p className="error">{error_form_bl?.state}</p> : null}
          {showError === "state" ? <p className="error">This field is required</p> : null}
        </div>
        <div className="row row-field sub-field">
          <Input
            value={form_bl?.postcode}
            onChange={handleBillingChange}
            label="Postcode"
            required
            error=""
            type="text"
            name="postcode"
            onBlur={handleValidate}
          />
          {error_form_bl?.postcode ? <p className="error">{error_form_bl?.postcode}</p> : null}
          {showError === "postcode" ? <p className="error">This field is required</p> : null}
          <Input
            value={form_bl?.phone}
            onChange={handleBillingChange}
            label="Phone"
            required
            error=""
            type="text"
            name="phone"
            onBlur={handleValidate}
          />
          {error_form_bl?.phone ? <p className="error">{error_form_bl?.phone}</p> : null}
          <Input
            value={form_bl?.email}
            onChange={handleBillingChange}
            label="Email address"
            required
            error=""
            type="text"
            name="email"
            onBlur={handleValidate}
          />
          {error_form_bl?.email ? <p className="error">{error_form_bl?.email}</p> : null}
          {showError === "email" ? <p className="error">This field is required</p> : null}
        </div>
      </div>
      <div className="row-wrap row-right">
        <h2 className="title-details title-address">
          <div className="address-top">
            <input
              type="checkbox"
              id="address"
              checked={viewOtherAddress}
              onChange={() => setViewOtherAddress(!viewOtherAddress)}
            />
            <label htmlFor="address">Deliver to a different address?</label>
          </div>
          <div className={`other-address ${classView}`}>
            <div className="row row-name">
              <div className="col">
                <Input
                  value={form_deliver?.first_name}
                  onChange={handleDeliverChange}
                  label="First name"
                  required
                  error=""
                  type="text"
                  name="first_name"
                  onBlur={handleValidate}
                />
                {error_form_deliver?.first_name && viewOtherAddress ? (
                  <p className="error">{error_form_deliver?.first_name}</p>
                ) : null}
                {showError === "first_name" ? <p className="error">This field is required</p> : null}
              </div>
              <div className="col">
                <Input
                  value={form_deliver?.last_name}
                  onChange={handleDeliverChange}
                  label="Last name"
                  required
                  error=""
                  type="text"
                  name="last_name"
                  onBlur={handleValidate}
                />
                {error_form_deliver?.last_name && viewOtherAddress ? (
                  <p className="error">{error_form_deliver?.last_name}</p>
                ) : null}
                {showError === "last_name" ? <p className="error">This field is required</p> : null}
              </div>
            </div>
            <div className="row row-field not-require sub-field">
              <Input
                value={form_deliver?.company}
                onChange={handleDeliverChange}
                label="Company name (optional)"
                error=""
                type="text"
                name="company"
              />
            </div>
            <div className="row row-field row-select">
              <label required htmlFor="">
                Country/Region
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <strong>Australia</strong>
            </div>
            <div className="row row-field street-first">
              <Input
                value={form_deliver?.house_number}
                onChange={handleDeliverChange}
                className="input-street"
                label="Street address"
                placeholder="House number and street name"
                required
                type="text"
                error=""
                name="house_number"
                onBlur={handleValidate}
              />
              {error_form_deliver?.house_number && viewOtherAddress ? (
                <p className="error">{error_form_deliver?.house_number}</p>
              ) : null}
              {showError === "house_number" ? <p className="error">This field is required</p> : null}
            </div>
            <div className="row row-field not-require street-none">
              <Input
                value={form_deliver?.apartment}
                onChange={handleDeliverChange}
                className="input-street"
                placeholder="Apartment, suite, unit, etc. (optional)"
                error=""
                type="text"
                name="apartment"
              />
            </div>
            <div className="row row-field sub-field">
              <Input
                value={form_deliver?.suburb}
                onChange={handleDeliverChange}
                label="Suburb"
                required
                error=""
                type="text"
                name="suburb"
                onBlur={handleValidate}
              />
              {error_form_deliver?.suburb && viewOtherAddress ? (
                <p className="error">{error_form_deliver?.suburb}</p>
              ) : null}
              {showError === "suburb" ? <p className="error">This field is required</p> : null}
            </div>
            <div className="row row-field row-select">
              <label htmlFor="regions">
                State
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <select
                name="state"
                value={form_deliver?.state}
                onChange={handleDeliverChange}
                id="regions"
                required
                onBlur={handleValidate}
              >
                <option value="">Select an option…</option>
                <option value="ACT">Australian Capital Territory</option>
                <option value="NSW">New South Wales</option>
                <option value="NT">Northern Territory</option>
                <option value="QLD">Queensland</option>
                <option value="SA">South Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="VIC">Victoria</option>
                <option value="WA">Western Australia</option>
              </select>
              {error_form_deliver?.state && viewOtherAddress ? (
                <p className="error">{error_form_deliver?.state}</p>
              ) : null}
              {showError === "state" ? <p className="error">This field is required</p> : null}
            </div>
            <div className="row row-field sub-field">
              <Input
                value={form_deliver?.postcode}
                onChange={handleDeliverChange}
                label="Postcode"
                required
                error=""
                type="text"
                name="postcode"
                onBlur={handleValidate}
              />
              {error_form_deliver?.postcode && viewOtherAddress ? (
                <p className="error">{error_form_deliver?.postcode}</p>
              ) : null}
              {showError === "postcode" ? <p className="error">This field is required</p> : null}
            </div>
          </div>
          <div className="row row-field not-require row-order">
            <label className="order-label" htmlFor="area">
              Order notes (Optional)
            </label>
            <textarea
              value={form_deliver?.order_note}
              onChange={handleDeliverChange}
              className="order-note"
              id="area"
              placeholder="Notes about your order, e.g. special notes for delivery."
              type="text"
              name="order_note"
            />
          </div>
        </h2>
      </div>
    </div>
  )
}

export default BillingDetails
