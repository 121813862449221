import { keyBy } from "lodash";
import React, { useState } from "react"
import "./Input.component.scss"
const Input = ({
  label,
  required,
  error,
  onChange,
  onChange_deliver,
 value,
 title,
 name,
  ...props
}) => {
  return (
    <div className={`${error && "error"} c-input`}>
      <label className="required-label" htmlFor="">{label}
        <abbr className="required" title="required">*</abbr>
      </label>
      <input
      value={value}
      name={name}
      onChange={onChange}
        {...props }
        {...required } />
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
    </div>
  )
}

export default Input